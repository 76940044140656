import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import useProductPages from '~/hooks/useProductPages';
import { Icon, IconType } from '../elements/Icon';
import Image from '../elements/Image';

const labelVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0, delay: 0 },
  },
};

const ProductNav = () => {
  const [openNav, setOpenNav] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const data = useProductPages();

  const productPages = data?.wp?.header?.optionsHeader?.header?.menu[0]?.submenu;

  useEffect(() => {
    let url;

    if (typeof window !== 'undefined') {
      url = window.location.pathname.replace(/\/$/, '');
    }

    const search = (obj) => {
      let searchUrl = obj.link?.url;
      if (searchUrl.includes(location.hostname)) {
        searchUrl = new URL(searchUrl).pathname;
      }

      return searchUrl.replaceAll('/', '') === url.replaceAll('/', '');
    };
    setActivePage(productPages.findIndex(search));
  }, [productPages]);

  return (
    <>
      <AnimatePresence>
        {openNav && (
          <motion.div
            style={{ willChange: 'opacity' }}
            className="fixed inset-0 z-[90] h-screen  "
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div
              onClick={() => setOpenNav(false)}
              className="w-full h-full z-[90] bg-dark-purple bg-opacity-[0.83] relative"
            >
              <div className="absolute top-[12px] right-[1rem] md:top-[46px] md:right-[49px]">
                <div className="cursor-pointer" onClick={() => setOpenNav(false)}>
                  <ExitSvg />
                </div>
              </div>
              <div className="flex items-center justify-center w-full h-full z-[90] px-3">
                <div className="bg-white max-w-[432px] rounded-[10px] py-[23px] w-full overflow-scroll max-h-[595px] scrollbar-hidden">
                  {productPages?.map((link, i) => (
                    <ProductNavItem
                      key={`prodnav${i}`}
                      {...link}
                      i={i}
                      isLast={i === productPages?.length - 1}
                      activePage={activePage}
                    />
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="fixed  lg:px-0 bottom-[23px] md:bottom-[21px] left-0 right-0 z-[100] hidden md:block">
        <div className="mx-auto ">
          <div className="flex items-center justify-between w-full px-[23px] md:px-[23px] ">
            <div className={`flex-auto  ${activePage === 0 && 'opacity-20 pointer-events-none'}`}>
              <ArrowSvg
                uri={productPages[activePage - 1]?.link?.url}
                title={productPages[activePage - 1]?.link?.title}
                labelClass="pl-[60px] pr-[29px]"
              />
            </div>
            <NavButton onClick={() => setOpenNav(true)} openNav={openNav} />
            <div
              className={`flex-auto rotate-180 ${
                activePage === productPages?.length - 1 && 'opacity-20 pointer-events-none'
              }`}
            >
              <Link to={productPages[activePage + 1]?.link?.url}>
                <ArrowSvg
                  title={productPages[activePage + 1]?.link?.title}
                  labelClass={`rotate-180 pr-[60px] pl-[29px]`}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductNav;

export const NavButton = ({ onClick, openNav }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <div>
      <div
        className="relative flex items-center cursor-pointer "
        onClick={onClick}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: onHover || openNav ? 85 : 0 }}
          transition={{ duration: 0.3 }}
          className="relative top-0 "
        >
          <svg
            className="relative z-10"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" transform="rotate(180 24 24)" fill="#82EBBE" />
            <path
              d="M25.5 15V22.5H33V15H25.5ZM25.5 33H33V25.5H25.5V33ZM15 22.5H22.5V15H15V22.5ZM15 33H22.5V25.5H15V33Z"
              fill="#030135"
            />
          </svg>

          <motion.div
            initial={{ width: '0%', opacity: 0 }}
            animate={{ width: onHover || openNav ? '400%' : '0%', opacity: onHover || openNav ? 1 : 0 }}
            exit={{ width: '0%', opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 right-[1px] text-white rounded-full bg-dark-purple "
          >
            <AnimatePresence>
              {(onHover || openNav) && (
                <motion.div
                  variants={labelVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className=" py-[13px] w-full  whitespace-nowrap pl-[29px] pr-[66px] font-heading text-[14px] font-bold tracking-[-0.005em] "
                >
                  Our Products
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export const ProductNavItem = ({ link, icon, i, isLast, description, activePage }) => {
  return (
    <>
      <motion.div whileHover="hover" initial="rest">
        <Link
          to={link?.url}
          className={`px-[29px] py-[20px] hover:bg-light-grey flex items-center ${
            activePage === i && 'opacity-20 pointer-events-none'
          }`}
        >
          <div className="w-full max-w-[47px]">
            <Image image={icon} className="w-full h-full max-w-[47px]" />
          </div>
          <div className="ml-[23px]">
            <div className=" font-heading text-[15.7px] leading-[1.19] font-bold tracking-[-0.005em] ">
              {link?.title}
            </div>
            <div className="max-w-[275px]">{description}</div>
          </div>
          <div className="ml-auto">
            <motion.div
              variants={{
                hover: {
                  scale: 1,
                  opacity: 1,
                },
                rest: {
                  scale: 0,
                  opacity: 0,
                  rotate: 180,
                },
              }}
            >
              <Icon type={IconType.GreenBgArrow} />
            </motion.div>
          </div>
        </Link>
      </motion.div>
      {!isLast && <div className="max-w-[374px] h-[1px] bg-black w-[86%] mx-auto bg-opacity-[0.07] mt-[-1px] "></div>}
    </>
  );
};

export const ArrowSvg = ({ title, labelClass, uri }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <div className="relative w-full overflow-hidden ">
      <Link to={uri} className="max-w-max">
        <svg
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className="relative z-10"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" transform="rotate(180 24 24)" fill="#82EBBE" />
          <path d="M33 23.6743L14.6446 23.6743" stroke="#030135" strokeWidth="2.08584" />
          <path d="M21.3193 17L14.6447 23.6747L21.3193 30.3494" stroke="#030135" strokeWidth="2.08584" />
        </svg>

        <motion.div
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          initial={{ width: '0%', opacity: 0 }}
          animate={{ width: onHover ? '100%' : '0%', opacity: onHover ? 1 : 0 }}
          exit={{ width: '0%', opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="absolute flex justify-center top-0 left-[1px] text-white rounded-full bg-dark-purple max-w-max "
        >
          <AnimatePresence>
            <motion.div
              variants={labelVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              // initial={{ width: '0%', opacity: 0 }}
              // animate={{ width: onHover ? '100%' : '0%', opacity: onHover ? 1 : 0 }}
              // exit={{ width: '0%', opacity: 0 }}
              className={`py-[13px] w-full  whitespace-nowrap  font-heading text-[14px] font-bold tracking-[-0.005em] ${labelClass} `}
            >
              {title}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </Link>
    </div>
  );
};

export const ExitSvg = ({}) => {
  return (
    <svg
      className="transition-colors duration-150 hover:text-white group "
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="transition-colors duration-150 group-hover:fill-current"
        cx="24"
        cy="24"
        r="24"
        fill="#82EBBE"
      />
      <path d="M16 16L31.5 31.5" stroke="#030135" stroke-width="2" />
      <path d="M16 31.5L31.5 16" stroke="#030135" stroke-width="2" />
    </svg>
  );
};
