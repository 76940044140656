import { graphql, useStaticQuery } from 'gatsby';

const useProductPages = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: { typePage: { isProductPage: { eq: true } } }) {
        nodes {
          id
          uri
          title
          typePage {
            isProductPage
            productPageIcon {
              ...Image
            }
          }
        }
      }
      wp {
        header {
          optionsHeader {
            header {
              menu {
                submenu {
                  description
                  link {
                    title
                    url
                  }
                  icon {
                    ...Image
                  }
                }
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

export default useProductPages;
